import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';
import { TickboxActionResponse } from './TickboxAction';

const TickboxPreview: FC<ActionPreviewBaseProps<TickboxActionResponse>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;

  const { t } = useTranslation('activity-type');

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const answer = useMemo(() => {
    if (!response) {
      return '-';
    }

    return t(`tickbox.${response?.checked ? 'checked' : 'unchecked'}`);
  }, [response, t]);

  if (answerOnly) {
    return <>{answer}</>;
  }

  return <ActionPreview data-cy="tickbox-preview" question={title} description={previewDescription} answer={answer} />;
};

export default TickboxPreview;
